import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { prepareHeaders } from "../../auth/slice/AuthSlice";

const apiUrl = `/api`;
const customerUrl = `${apiUrl}/customer`;

export const fetchCategoryList = createAsyncThunk(
  "questionnaires/fetchCategoryList",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const headers = prepareHeaders(state);
      const response = await axiosInstance.get(
        `${apiUrl}/question-category/list`,
        {
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchDefaultQuestionList = createAsyncThunk(
  "questionnaires/fetchDefaultQuestionList",
  async (code, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${customerUrl}/sku/showing-default-question/${code}`,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchQuestionList = createAsyncThunk(
  "questionnaires/fetchQuestionList",
  async (code, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${customerUrl}/question/${code}`,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAdditionalInfoList = createAsyncThunk(
  "questionnaires/fetchAdditionalInfoList",
  async (code, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${customerUrl}/question_add_on/${code}`,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const storeDraftAnswer = createAsyncThunk(
  "questionnaires/storeDraftAnswer",
  async ({ code, id }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${customerUrl}/answer/store_draft_answer`,
        {
          params: { reference_code: code, question_category_id: id },
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const saveDefaultQuestionnaireData = createAsyncThunk(
  "skus/saveDefaultQuestionnaireData",
  async (newDefaultQuestionnaire, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${customerUrl}/answer/store-default-question-answer`,
        newDefaultQuestionnaire,
        {
          headers: prepareHeaders(state, true, {
            "Content-Type": "multipart/form-data",
          }),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const saveQuestionnaireData = createAsyncThunk(
  "skus/saveQuestionnaireData",
  async (newQuestionnaire, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${customerUrl}/answer/store`,
        newQuestionnaire,
        {
          headers: prepareHeaders(state, true, {
            "Content-Type": "multipart/form-data",
          }),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const changeRequestStatusToProgress = createAsyncThunk(
  "questionnaires/changeRequestStatusToProgress",
  async (code, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${customerUrl}/sku/in-progress/${code}`,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchSkuInfo = createAsyncThunk(
  "skus/fetchSkuInfo",
  async (code, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${customerUrl}/sku/showing/${code}`,
        {
          headers: prepareHeaders(state, true),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchSkuLog = createAsyncThunk(
  "skus/fetchSkuLog",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerUrl}/sku/log/${id}`, {
        headers: prepareHeaders(state, true),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const saveAdditionalInfoData = createAsyncThunk(
  "skus/saveAdditionalInfoData",
  async (newAdditionalInfo, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${customerUrl}/question_add_on/store`,
        newAdditionalInfo,
        {
          headers: prepareHeaders(state, true, {
            "Content-Type": "multipart/form-data",
          }),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const QuestionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: {
    questionnaireStatus: null,
    skuStatus: null,
    defineCatStatus: null,
    questionnaire: null,
    additionalInfo: null,
    skuInfo: null,
    categoryList: [],
    defaultQuestionList: [],
    questionsList: [],
    additionalInfoList: [],
    logs: [],
    loading: false,
    isSkuInfoLoading: false,
    isSavingDefaultQuestionnaire: false,
    isSavingQuestionnaire: false,
    isSavingAdditionalInfo: false,
    isLogloading: false,
    isDraftSaving: false,
    categoryLoading: false,
    error: null,
    validationError: [],
    success: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = "";
    },
    clearSuccess: (state) => {
      state.success = null;
    },
    setQuestionnaireStatus: (state, action) => {
      state.questionnaireStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch category list
      .addCase(fetchCategoryList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchCategoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryList = action.payload.payload;
      })
      .addCase(fetchCategoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch question list
      .addCase(fetchQuestionList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchQuestionList.fulfilled, (state, action) => {
        state.loading = false;
        state.questionList = action.payload.payload.data;
      })
      .addCase(fetchQuestionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch default question list
      .addCase(fetchDefaultQuestionList.pending, (state) => {
        state.isDefaultLoading = true;
        state.error = "";
      })
      .addCase(fetchDefaultQuestionList.fulfilled, (state, action) => {
        state.isDefaultLoading = false;
        const data = action.payload?.payload;
        const defaultQuestions = data?.default_questions;
        state.defaultQuestionList = defaultQuestions ? defaultQuestions : [];
        state.skuStatus = data?.sku_status;
        state.defineCatStatus = data?.define_question_status;
      })
      .addCase(fetchDefaultQuestionList.rejected, (state, action) => {
        state.isDefaultLoading = false;
        state.error = action.payload;
      })
      // fetch sku
      .addCase(fetchSkuInfo.pending, (state) => {
        state.isSkuInfoLoading = true;
        state.error = "";
      })
      .addCase(fetchSkuInfo.fulfilled, (state, action) => {
        state.isSkuInfoLoading = false;
        state.skuInfo = action.payload.payload;
      })
      .addCase(fetchSkuInfo.rejected, (state, action) => {
        state.isSkuInfoLoading = false;
        state.error = action.payload;
      })
      // fetch sku log
      .addCase(fetchSkuLog.pending, (state) => {
        state.isLogLoading = true;
        state.error = "";
      })
      .addCase(fetchSkuLog.fulfilled, (state, action) => {
        state.isLogLoading = false;
        state.logs = action.payload.payload;
      })
      .addCase(fetchSkuLog.rejected, (state, action) => {
        state.isLogLoading = false;
        state.error = action.payload;
      })
      // store draft answer
      .addCase(storeDraftAnswer.pending, (state) => {
        state.isDraftSaving = true;
        state.error = "";
      })
      .addCase(storeDraftAnswer.fulfilled, (state, action) => {
        state.isDraftSaving = false;
      })
      .addCase(storeDraftAnswer.rejected, (state, action) => {
        state.isDraftSaving = false;
        state.error = action.payload;
      })
      // change Request Status To Progress
      .addCase(changeRequestStatusToProgress.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(changeRequestStatusToProgress.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeRequestStatusToProgress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // save questionnaire data
      .addCase(saveQuestionnaireData.pending, (state) => {
        state.isSavingQuestionnaire = true;
        state.error = "";
      })
      .addCase(saveQuestionnaireData.fulfilled, (state, action) => {
        state.isSavingQuestionnaire = false;
        state.questionnaire = action.payload?.payload;
      })
      .addCase(saveQuestionnaireData.rejected, (state, action) => {
        state.isSavingQuestionnaire = false;
        if (typeof action.payload !== "string") {
          state.validationError = action.payload;
        }
      })
      // save default questionnaire data
      .addCase(saveDefaultQuestionnaireData.pending, (state) => {
        state.isSavingDefaultQuestionnaire = true;
        state.error = "";
      })
      .addCase(saveDefaultQuestionnaireData.fulfilled, (state, action) => {
        state.isSavingDefaultQuestionnaire = false;
        state.defaultQuestionList = action.payload?.payload ?? [];
      })
      .addCase(saveDefaultQuestionnaireData.rejected, (state, action) => {
        state.isSavingDefaultQuestionnaire = false;
        state.error = action.payload;
      })
      // fetch additional info list
      .addCase(fetchAdditionalInfoList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAdditionalInfoList.fulfilled, (state, action) => {
        state.loading = false;
        state.additionalInfoList = action.payload.payload.data;
      })
      .addCase(fetchAdditionalInfoList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // save additional info data
      .addCase(saveAdditionalInfoData.pending, (state) => {
        state.isSavingAdditionalInfo = true;
        state.error = "";
      })
      .addCase(saveAdditionalInfoData.fulfilled, (state, action) => {
        state.isSavingAdditionalInfo = false;
        state.additionalInfo = action.payload.payload;
      })
      .addCase(saveAdditionalInfoData.rejected, (state, action) => {
        state.isSavingAdditionalInfo = false;
        state.error = action.payload;
      });
  },
});

export const { clearError, clearSuccess, setQuestionnaireStatus } =
  QuestionnaireSlice.actions;
export default QuestionnaireSlice.reducer;
