import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";

const GuestLayout = lazy(() => import("./modules/containers/GuestLayout"));
const Profile = lazy(() => import("./modules/profile"));
const ForgotPassword = lazy(() => import("./modules/auth/ForgotPassword"));
const Login = lazy(() => import("./modules/auth/Login"));
const HomePage = lazy(() => import("./modules/pages/HomePage"));
const Help = lazy(() => import("./modules/pages/Help"));
const PageNotFound = lazy(() => import("./modules/pages/PageNotFound"));
const NewProtectedLayout = lazy(() =>
  import("./modules/containers/NewProtectedLayout")
);
const ProtectedRoute = lazy(() =>
  import("./modules/containers/ProtectedRoute")
);
const PublicRoute = lazy(() => import("./modules/containers/PublicRoute"));
const Register = lazy(() => import("./modules/auth/Register"));
const Dashboard = lazy(() => import("./modules/dashboard"));
const MySkus = lazy(() => import("./modules/sku"));
const BulkQuestionnaire = lazy(() =>
  import("./modules/questionnaire/bulkQuestionnaire")
);
const QuestionnaireDefaultComplete = lazy(() =>
  import("./modules/questionnaire/defaultComplete")
);
const QuestionnaireComplete = lazy(() =>
  import("./modules/questionnaire/complete")
);
const AdditionalInfoComplete = lazy(() =>
  import("./modules/questionnaire/additionalInfoComplete")
);
const PurchaseSku = lazy(() => import("./modules/sku/PurchaseSku"));
const PurchaseSkuConfirm = lazy(() =>
  import("./modules/sku/PurchaseSkuConfirm")
);
const PurchaseSkuComplete = lazy(() =>
  import("./modules/sku/PurchaseSkuComplete")
);
const ContactPage = lazy(() => import("./modules/pages/ContactPage"));
const ServicesPage = lazy(() => import("./modules/pages/ServicesPage"));
const Billing = lazy(() => import("./modules/billing"));
const Nofitication = lazy(() => import("./modules/notification"));

function App() {
  const language = useSelector((state) => state.language.language);
  const { paymentIntent, sku } = useSelector((state) => state.sku);
  const { i18n } = useTranslation(); // eslint-disable-next-line
  const [key, setKey] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(language).then(() => {
      setKey((prevKey) => prevKey + 1);
    });
  }, [language, i18n]);
  return (
    <div key={language} className="bg-gray-50">
      <BrowserRouter>
        <Routes>
          <Route element={<GuestLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/services" element={<ServicesPage />} />
          </Route>
          <Route
            element={
              <ProtectedRoute>
                <NewProtectedLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/home" element={<Dashboard />} />
            <Route path="/help" element={<Help />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/my-sku" element={<MySkus />} />
            <Route path="/notification" element={<Nofitication />} />
            <Route
              path="/questionnaire/:code"
              element={<BulkQuestionnaire />}
            />
            <Route
              path="/questionnaire/:code/default/complete"
              element={<QuestionnaireDefaultComplete />}
            />
            <Route
              path="/questionnaire/:code/complete"
              element={<QuestionnaireComplete />}
            />
            <Route
              path="/questionnaire/:code/additional-info/complete"
              element={<AdditionalInfoComplete />}
            />
            <Route path="/purchase-sku" element={<PurchaseSku />} />
            {paymentIntent && (
              <Route
                path="/purchase-sku/confirm"
                element={<PurchaseSkuConfirm />}
              />
            )}
            {sku && (
              <Route
                path="/purchase-sku/complete"
                element={<PurchaseSkuComplete />}
              />
            )}
            <Route
              path="/purchase-sku/*"
              element={<Navigate to={"/purchase-sku"} replace />}
            />
          </Route>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/forget-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
